.sectionHeader {
    text-align: center;
    height: 100vh;
}

.logoCenterGradient {
    position: relative;
    margin: 0 auto;
    width: 500px;
    height: 500px;
    border-radius: 100%;
    background: linear-gradient(90deg, #ed1e79, #f15a24, #ed1e79);
    background-size: 200% 200%;
    animation: gradient 2s linear infinite;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoMask {
    width: 92%;
}

.pulseRing {
    position: absolute;
    z-index: -5;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    background-color: #1a1a1a;
}

.ring0 {
    animation: pulse 4s infinite;
    animation-delay: 0s;
}

.ring1 {
    animation: pulse 4s infinite;
    animation-delay: 0.75s;
}

.ring2 {
    animation: pulse 4s infinite;
    animation-delay: 2s;
}



@keyframes gradient {
    0% {
        background-position: 100% 0%;
    }

    100% {
        background-position: -100% 0%;
    }

}


@keyframes pulse {
    0% {
        opacity: 1;
        transform: scale(0.95);
        box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.4);
    }

    70% {
        box-shadow: 0 0 10px 10px rgba(255, 255, 255, 00);
    }

    100% {
        opacity: 0;
        transform: scale(1.5);
        box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0);
    }
}

.sectionMotto {
    text-align: center;
    padding-top: 140px;
    padding-bottom: 180px;
    background-position: center;
    background-size: 100% 100%;

}

.mottoTitle {
    width: 80%;
    margin: 0 auto !important;
    font-size: 60px !important;
    text-align: left;
}

.mottoImg {
    width: 80%;
    margin: 0 auto !important;
}



.underline {
    position: relative;
}

.underline::before {
    content: "";
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    height: 8px;
    border-radius: 20px;
    background: linear-gradient(90deg, #ed1e79, #f15a24);
}

.sectionProjectsTitle {
    text-align: center;
}

.sectionProjectsTitle h2 {
    width: 250px;
    text-align: center;
    margin: 40px auto 40px auto;
}

.projectRow {
    position: relative;
    padding: 50px 0px !important;
}

.buttonKnowMore {
    color: white;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 20px;
    letter-spacing: 2px;
    padding: 8px 40px !important;
    background: linear-gradient(90deg, #ed1e79, #f15a24);
    border-radius: 100px;
    border: none;
    transition: all 1s ease;
}

.buttonKnowMore:hover {
    transition: all 2s ease;
    background: linear-gradient(90deg, #f15a24, #f15a24);
}

.projectDesc {
    margin: 60px auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.projectDescDiv {
    width: 90%;
    max-width: 600px;
}

.projectLogo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.projectLogo img {
    width: 90%;
    max-width: 400px;
}

.pinkStrokeDiv {
    position: absolute;
    bottom: 0;
    width: 500px;
}

.sectionTeam {
    text-align: center;
}

.sectionJoin {
    width: 80%;
    max-width: 800px;
    position: relative;
    text-align: center;
}

.teamMemberDiv {
    position: relative;
    text-align: center;
    margin-top: 40px;
}

.teamPhoto {
    position: relative;
    width: 150px;
    z-index: 5;
}

.teamBorder {
    position: absolute;
    width: 160px;
    height: 160px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 160px;
    background: linear-gradient(0deg, #ed1e79, #f15a24);
}

.footerLinks {
    text-decoration: none;
    color: white;

}

.footerLinks:hover {
    color: #f15a24;
}

.footerCallBtn {
    color: #6a6a6a;
    text-decoration: none;
    font-weight: 900;
    background-color: white;
    padding: 5px 10px;
    border-radius: 20px;
}

.footerCallBtn:hover {
    background-color: #f15a24;
    color: white;
}

@media only screen and (max-width: 960px) {
    .mottoTitle {
        font-size: 40px !important;
    }
}

@media only screen and (max-width: 768px) {
    .logoCenterGradient {
        width: 250px;
        height: 250px;
    }

    .sectionMotto {
        padding-top: 80px;
        padding-bottom: 80px;

    }

    .mottoTitle {
        width: 90%;
        font-size: 30px !important;
    }

    .projectRow {
        padding: 10px 0px !important;
    }


}