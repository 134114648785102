.customNavBarStyle {
    color: #808080 !important;
    background: none !important;
}

.topBar {
    padding: 5px;
    background: none !important;

}

.navbar-brand img {
    height: 60px;
    padding-left: 20px;
}

.customNavBarStyle-links a {
    display: flex;
    align-items: center;
    vertical-align: middle;
    font-size: 24px !important;
    letter-spacing: 1px;
    padding: 0px 20px !important;
    color: white !important;
}

.customNavBarStyle-links a:hover {
    color: #ed1e79 !important;
}