body {
  margin: 0;
  font-family: 'Inconsolata', 'Open Sans', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff !important;
  background-color: #1a1a1a !important;
  text-align: left;
  font-weight: 700;
  overflow-x: hidden;
}

/* h1, h2, h3 {
  font-family: 'Montserrat', 'Helvetica Neue', 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif !important;
  font-weight: 900 !important;
  font-size: 70px !important;
} */

h1,
h2,
h3,
h4 {
  font-weight: 900 !important;
  text-transform: uppercase;
}

h1 {
  font-size: 60px !important;

}

h2 {
  font-size: 36 !important;
}

h3 {
  font-size: 28px !important;
}

h4 {
  font-size: 24px !important;
}

p {
  font-size: 20px !important;
}

.smallText {
  font-size: 16px !important;
}

.underlineText {
  text-decoration: underline;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.verticalAlign {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.noPadding {
  padding: 0 !important;
}

.padding {
  padding: 25px !important;
}



.smallPadding {
  padding: 5px !important;
}

.paddingTop {
  padding-top: 60px !important;
}

.bigPaddingTop {
  padding-top: 120px !important;
}

.bigPaddingBottom {
  padding-bottom: 120px !important;
}

.smallPaddingTop {
  padding-top: 20px !important;
}

.paddingSides {
  padding-left: 10vw !important;
  padding-right: 10vw !important;
}

.paddingSidesPx {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.marginTop {
  margin-top: 90px !important;
}

.bigMarginTop {
  margin-top: 150px !important;
}

.smallMarginTop {
  margin-top: 20px !important;
}

.smallAllMargin {
  margin: 20px;
}

.autoMargin {
  margin: auto !important;
}

.row {
  margin-left: auto !important;
  margin-right: auto !important;
}

.countNavbar {
  padding-top: 60px !important;
}

.width100 {
  width: 100% !important;
}

.height100 {
  height: 100vh;
}

.backgroundWhite {
  background-color: #e6e6e6 !important;
}

.backgroundPink {
  background-color: #ed1e79 !important;
}

.backgroundOrange {
  background-color: #f15a24 !important;
}

.colorWhite {
  color: #ffffff !important;
}

.colorGrey {
  color: #808080 !important;
}

.colorDarkGrey {
  color: #333 !important;
}

.colorBlack {
  color: #000000 !important;
}

.colorPink {
  color: #ed1e79 !important;
}

.colorDarkBlue {
  color: #1b1464;
}

.colorPurple {
  color: #93278f;
}

.colorYellow {
  color: #ffff00;
}

.colorBlue {
  color: #29abe2;
}

.colorOrange {
  color: #f15a24;
}

.boldText {
  font-weight: 900 !important;
}

.textCenter {
  text-align: center !important;
}

.textLeft {
  text-align: left !important;
}

.textRight {
  text-align: right !important;
}

.btnInactive {
  opacity: 0.5;
  cursor: default !important;
}

.btnNoNo {
  font-family: 'ARCOTypography';
  font-size: 28px !important;
  padding: 20px 40px;
  border: none;
  border-radius: 70px;
  background-color: #ed1e79;
  color: #ffffff;
}

.cursorPointer {
  cursor: pointer;
}

.lastDiv {
  height: 100px;
}

.hideMobile {
  display: block;
}

.showMobile {
  display: none !important;
}



.cloudRightDiv {
  position: absolute;
  top: 15%;
  right: 0;
  width: 20%;
  height: 50%;
  pointer-events: none;
  z-index: -5;
}

.cloudLeftDiv {
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 20%;
  height: 40%;
  pointer-events: none;
  z-index: -5;
}

.cloudImg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.cloudRightDiv .cloudImg {
  background-position: center right;
}

.bodyDivBorder {
  width: 100vw;
  height: 100vh;
  padding: 2.5vh 2.5vw;
  position: relative;
}

.borderDiv {
  border: 1px solid #999;
  height: 95vh;
  width: 95vw;
  overflow-y: scroll;
  position: relative;
  overflow-x: hidden;
}


@media only screen and (max-width: 960px) {

  h1 {
    font-size: 46px !important;
  }

  h2 {
    font-size: 30px !important;
  }

  h3 {
    font-size: 24px !important;
  }

  h4 {
    font-size: 20px !important;
  }

  p {
    font-size: 16px !important;
  }

  .btnNoNo {
    font-size: 18px !important;
  }

}

@media only screen and (max-width: 768px) {
  .hideMobile {
    display: none !important;
  }

  .showMobile {
    display: block !important;
  }

  h1 {
    font-size: 30px !important;
  }

}